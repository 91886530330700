/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyBz3tWzhhV-SoDoFB-Gp4xBpeMt6tGBI2M",
  "appId": "1:395231962690:web:bb70e1894c60716fa76fdb",
  "authDomain": "schooldog-instance-carroll.firebaseapp.com",
  "measurementId": "G-9XSK5Q23X5",
  "messagingSenderId": "395231962690",
  "project": "schooldog-instance-carroll",
  "projectId": "schooldog-instance-carroll",
  "showGaBranding": true,
  "storageBucket": "schooldog-instance-carroll.appspot.com"
}
